<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col class="text-right">
                <v-btn  color="grey" :to="{name: 'admin.documents.create'}">Create</v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="documents"
            :items-per-page="15"
            class="elevation-1"
        >
            <template v-slot:item.name="{ item }">
                <v-row class="flex-column">
                    <v-col class="cursor-pointer text--blue" @click="handleClick(item,'documents')">
                        {{ item.name_hy }}
                    </v-col>
                    <v-col class="cursor-pointer text--blue" @click="handleClick(item,'documents')">
                        {{ item.name_ru }}
                    </v-col>
                    <v-col class="cursor-pointer text--blue" @click="handleClick(item,'documents')">
                        {{ item.name_en }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.url="{ item }">
                <v-row class="cursor-pointer text--blue">
                    <v-col>
                        <a :href="item.url" target="_blank">Open</a>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item.created_at="{ item }">
                {{ new Date(item.created_at).toLocaleString() }}
            </template>
            <template v-slot:item.actions="{ item }">
                <document-delete-dialog @deleted="handleRemoveDocument" :document="item" />
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import DocumentDeleteDialog from "@/components/dialogs/DocumentDeleteDialog";
import Document from "@/models/Document";

export default {
    name: "documents-index",
    components: {DocumentDeleteDialog, AdminDashboardLayout},
    data(){
        return {
            loading: false,
            documents: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Name', value: 'name'},
                {text: 'URL', value: 'url'},
                {text: 'Created at', value: 'created_at'},
                {text: 'Actions', value: 'actions'},
            ],
        }
    },
    methods: {
        handleClick(item, namePath) {
            this.$router.push({name: `admin.${namePath}.show`, params: {id: item.id}})
        },
        handleRemoveDocument(id) {
            this.documents = this.$_.filter(this.documents, document => document.id !== id);
        }
    },
    async mounted() {
        this.loading = true
        this.documents = await Document.get()
        this.loading = false
    },
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}

.text--blue {
    color: #0d47a1;
}

.text--blue:hover {
    text-decoration: underline;
}
</style>
