var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin-dashboard-layout',[_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"grey","to":{name: 'admin.documents.create'}}},[_vm._v("Create")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.documents,"items-per-page":15},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"flex-column"},[_c('v-col',{staticClass:"cursor-pointer text--blue",on:{"click":function($event){return _vm.handleClick(item,'documents')}}},[_vm._v(" "+_vm._s(item.name_hy)+" ")]),_c('v-col',{staticClass:"cursor-pointer text--blue",on:{"click":function($event){return _vm.handleClick(item,'documents')}}},[_vm._v(" "+_vm._s(item.name_ru)+" ")]),_c('v-col',{staticClass:"cursor-pointer text--blue",on:{"click":function($event){return _vm.handleClick(item,'documents')}}},[_vm._v(" "+_vm._s(item.name_en)+" ")])],1)]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"cursor-pointer text--blue"},[_c('v-col',[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v("Open")])])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleString())+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('document-delete-dialog',{attrs:{"document":item},on:{"deleted":_vm.handleRemoveDocument}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }